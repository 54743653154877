import React from "react";
import { Link } from "react-router-dom";
import link1 from "../../assets/images/link1.png";
import link2 from "../../assets/images/link2.png";
import link3 from "../../assets/images/link3.png";
import link4 from "../../assets/images/link4.png";
import "./FixedLinks.css";

const FixedLinks = () => {
  return (
    <div className="fixed-links">
      <div>
        <a
          href="https://www.facebook.com/aswan.gov.eg"
          target={"_blank"}
          rel="noreferrer"
        >
          <img src={link1} alt="media-link" />
        </a>
      </div>
      <div>
        <a
          href="https://www.youtube.com/channel/UCUQh4XoP4N5Q4BMSPMJPZjQ"
          target={"_blank"}
          rel="noreferrer"
        >
          <img src={link3} alt="media-link" />
        </a>
      </div>
    </div>
  );
};
export default FixedLinks;
